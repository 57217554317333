import Layout, { Content, Header }  from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React, { useState } from "react";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    HomeOutlined,
    FileDoneOutlined,
    LogoutOutlined,
    MailOutlined,
    PhoneOutlined,
    HighlightOutlined,
    FileTextOutlined,
    SafetyCertificateOutlined
} from '@ant-design/icons';

import "./globalLayout.scss";
import { Button, Image, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import buildClassName from "../../Utils/buildClassName";

// const { Link } = Anchor;

export default function GlobalLayout(
    {
        children ,
        titulo
    }
) {

    
    
    const [collapsed, setCollapsed] = useState(false);
    const onCollapse = (collapsed) => {
        setCollapsed(collapsed)
    };

    function NavElement({
        nombre,
        icon,
        url = "",
        isColumn = true,
        collapsed = false,
    }) {
    
        const { pathname } = useLocation();
        const history = useNavigate();
        
        const isActive = pathname === url;
        
        
        let className = buildClassName([
            "navElementContainer w100 cp ta03 pl3 pr3 aic",
            {
                "navElementContainerActive": isActive,
                "pt1 pb1": isColumn,
                "jcc": collapsed,
            }
        ]);
        
        
        let nodoIcon = icon;
        
        if (collapsed) nodoIcon = (
            <Tooltip
                title={nombre}
                placement="right"
            >
                {icon}
            </Tooltip>
        );
        
        
        
        return (
            <Button
                type="link"
                name={url}
                className={className}
                style={{
                    height: "2em",
                    color: "black",
                    width: "100%",
                    textAlign : "left"
                }}
                icon={nodoIcon}
                onMouseDown={ev => {
                    
                    ev.stopPropagation();
                    ev.preventDefault();
                    
                    if (ev.button === 1) { // 0 izq, 1 central, 2 der
                        window.open(url, "_blank", "noopener");
                        return
                    } else if (ev.button === 0) {
                        history(url);
                    };
                    
                    
                    
                }}
                
            >
                {!collapsed && <span className="ml2">{nombre}</span>}
            </Button>
        );
        
    }

    return(
        <>
            <Layout
                style={{
                    minHeight: '100vh',
                }}
            >
                
                <Sider 
                    breakpoint="lg"
                    collapsedWidth="0"
                    // onBreakpoint={broken => {
                    //     console.log(broken);
                    // }}
                    // onCollapse={(collapsed, type) => {
                    //     console.log(collapsed, type);
                    // }}
                    ////////
                    trigger={null} 
                    onCollapse={onCollapse} 
                    collapsible 
                    collapsed={collapsed}
                >
                    <Header 
                        className="header-logo"
                    >    
                        <Image
                            src="/img/logo.4b9fe3b8.svg"
                            w="100%"
                            preview={false}
                        /> 
                    </Header>

                    {/* WRAPPER FOR NAV ELEMENTS */}
                    <div style={{paddingTop:"20px", paddingLeft:"5px"}}>
                        <NavElement
                            nombre="Inicio"
                            url="/dashboard"
                            icon={<HomeOutlined className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Firmas"
                            url="/gestion"
                            icon={<FileDoneOutlined className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Emails validados"
                            url="/emails"
                            icon={<MailOutlined className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Colaborador por IP"
                            url="/signaturesControl"
                            icon={<HighlightOutlined  className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Contratos por IP"
                            url="/signaturesControlIp"
                            icon={<HighlightOutlined  className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Telefonos validados"
                            url="/telefonos"
                            icon={<PhoneOutlined className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Paises permitidos"
                            url="/paises"
                            icon={<SafetyCertificateOutlined className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Tareas Mala Praxis"
                            url="/praxis"
                            icon={<FileTextOutlined className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                        <NavElement
                            nombre="Salir"
                            url="/logout"
                            icon={<LogoutOutlined className="fs4" />}
                            isColumn={false}
                            collapsed={collapsed}
                        />
                    </div>

                </Sider>

                <Layout className="site-layout">
                    <Header 
                        className="site-layout-background" style={{ padding: 0 }}
                    >
                    {
                        // Creamos el elemento de manera dinamica
                        React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: () => {setCollapsed(!collapsed)},
                        })
                    }
                    {titulo}
                    </Header>

                    <Content style={{backgroundColor: "white"}}>
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}