const download = require("downloadjs");

const descargarSimple = (archivo, nombre, mimeType) => {

    const contenido = new Blob( [archivo], {type: mimeType});
    const url = window.URL.createObjectURL( contenido );
            
    const link = document.createElement("a");
    link.href = url;

    // descomentar para abrir la descarga desde otra pestaña
    link.setAttribute("target", "_blank");

    link.setAttribute("download", nombre);
    document.body.appendChild(link);
    
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
}

exports.descargarSimple = descargarSimple;


exports.descargar = (archivo, nombre, mimeType) => {

    const contenido = new Blob( [archivo] );
    return download(contenido, `${nombre}`, mimeType);
    
}
exports.visualizar = (archivo,nombre,mimeType) =>{

    const BlobArchivo = new Blob([archivo], { type: mimeType });
    const options = `
        location=no,
        scrollbars=yes,
        status=no,
        menubar=no,
        titlebar=no,
        toolbar=no,
        top=500,
        left=500,
        width=1200,
        height=800,
    `;
    
    const urlArchivo = URL.createObjectURL( BlobArchivo )
    
    return window.open( urlArchivo, "AbrirArchivo", options)
}