import { Button, Card, Col, Radio, Row, Space, Tooltip, Pagination, Select, Spin, Input, DatePicker, message } from "antd"
import { useEffect, useState } from "react"
import "./praxisSub.scss"
import Void from "../../atomicComponents/Void"
import apiRequest from "../../Utils/apiRequest"
import '../../components/FlatCard/flatCard.scss'
import { SearchOutlined, ArrowLeftOutlined, DeleteOutlined, MenuOutlined, MinusOutlined, CaretUpOutlined, CaretDownOutlined, CopyOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useSearchParams } from "react-router-dom"
import dayjs from "dayjs"

const sortTypes = [
    0,
    1,
    -1,
];

const { RangePicker } = DatePicker;

export default function PraxisSub() {
        
    const [searchParams] = useSearchParams();
    const id_usuario = searchParams.get("id");
    const [total, setTotal] = useState(0);

    const [paginacion, setPaginacion] = useState({ 
        totalPorPagina : 10,
        pagina: 1,
        sort: {
            column: "",
            type: 0
        }
    });

    const [filtros, setFiltros] = useState({});

    const [buscadorActivo, setBuscadorActivo] = useState(false);
    const [detallesActivo, setDetallesActivo] = useState(true);

    const [cargando, setCargando] = useState(true);
    const [listadoTareas, setListadoTareas] = useState([]);
    const [infoTareasGeneral, setInfoTareasGeneral] = useState({});
    const [infoTareasFiltro, setInfoTareasFiltro] = useState({});

    const [listadoSubusuarios, setListadoSubusuarios] = useState([]);
    const [listadoGestor, setListadoGestor] = useState([]);
    const [malaPraxisListado, setMalaPraxisListado] = useState([]);


    useEffect(() => {

        ( async () => {

            const diccionarioMalaPraxis = await apiRequest("get", '/diccionario?id=casuisticasMalaPraxis' , {}, true);

            if ( diccionarioMalaPraxis ) setMalaPraxisListado( diccionarioMalaPraxis );
            
            await buscarSubusuarios();
            await buscarInfoTareasGeneral();
            await buscarInfoTareasFiltro();
            await buscarTareas();
            
        })();

    }, []);


    const buscarSubusuarios = async () => {

        const get_listadoSubusuarios = await apiRequest("get", '/subusuarios' , {}, true);
        setListadoSubusuarios( get_listadoSubusuarios.subusuarios );
        setListadoGestor( get_listadoSubusuarios.subusuarios.filter( _x => _x.role === 28 ) );

    }
    const buscarTareas = async (_paginacion) => {
        setCargando( true );

        const _url = generarQueryTareas( _paginacion, 'tareasColaboradorPraxis' );

        buscarInfoTareasFiltro();
        const { tareas, total } = await apiRequest("get", _url, {}, true);
        setListadoTareas(tareas);
        setCargando( false );
        setTotal(total);

    }
    const buscarInfoTareasGeneral = async () => {

        let getInfoTareasGeneral = await apiRequest("get", `/malaPraxis?idUser=${ id_usuario }`, {}, true);
        setInfoTareasGeneral( getInfoTareasGeneral?.datos[0] ?? {} );

    }
    const buscarInfoTareasFiltro = async () => {

        const _filtros = { ...filtros };

        let subusuarios = filtros.subusuarios ? filtros.subusuarios.map( _x => parseInt( _x.key.split("_")[1] ) ) : [];

        filtros.gestores && filtros.gestores.forEach( _ges => {
            listadoSubusuarios.forEach( _sub => +_sub.id_gestor === +_ges.key.split("_")[1] && subusuarios.push( _sub.id ) )
        });

        _filtros.subusuarios = [ ...new Set( subusuarios ) ];
        
        let getInfoTareasFiltro = await apiRequest("get", `/malaPraxis?idUser=${ id_usuario }&filtros=${ JSON.stringify( _filtros ) }`, {}, true);
        setInfoTareasFiltro( getInfoTareasFiltro?.datos[0] ?? {} );

    }

    const generarQueryTareas = (_paginacion, _endpoint) => {

        let subusuarios = filtros.subusuarios ? filtros.subusuarios.map( _x => parseInt( _x.key.split("_")[1] ) ) : [];

        filtros.gestores && filtros.gestores.forEach( _ges => {
            listadoSubusuarios.forEach( _sub => +_sub.id_gestor === +_ges.key.split("_")[1] && subusuarios.push( _sub.id ) )
        });

        subusuarios = [ ...new Set( subusuarios ) ];

        let _url = `/${ _endpoint }?colaborador=${id_usuario}`;

        if ( subusuarios.length ) _url += `&subusuarios=${ subusuarios }`;

        if ( filtros.estado ) _url += `&estado=${ filtros.estado }`;
        if ( filtros.cups ) _url += `&cups=${ filtros.cups }`;
        if ( filtros.aclara ) _url += `&aclara=${ filtros.aclara }`;
        if ( filtros.aporta ) _url += `&aporta=${ filtros.aporta }`;
        if ( filtros.conforme ) _url += `&conforme=${ filtros.conforme }`;
        if ( filtros.deuda ) _url += `&deuda=${ filtros.deuda }`;
        if ( filtros.fechaCreacion ) _url += `&fechaCreacion=${ JSON.stringify( { inicio: dayjs( filtros.fechaCreacion[0] ).startOf( "day" ).unix(), fin : dayjs( filtros.fechaCreacion[1] ).endOf( "day" ).unix() } ) }`;
        if ( filtros.fechaCreacionContrato ) _url += `&fechaCreacionContrato=${ JSON.stringify( { inicio: dayjs( filtros.fechaCreacionContrato[0] ).startOf( "day" ).unix(), fin : dayjs( filtros.fechaCreacionContrato[1] ).endOf( "day" ).unix() } ) }`;
        if ( filtros.contestado ) _url += `&contestado=${ filtros.contestado }`;

        _url += `&praxis=${ !isNaN(filtros.praxis) ? filtros.praxis : null  }`;
        _url += `&paginacion=${ JSON.stringify( _paginacion ?? paginacion ) }`;

        return _url;

    }
    const cambiarPagina = ( _paginacion ) => { 
        setPaginacion({ ..._paginacion, pagina: _paginacion.pagina, totalPorPagina: _paginacion.registros }) 
        buscarTareas({ ..._paginacion, pagina: _paginacion.pagina, totalPorPagina: _paginacion.registros });
    }
    const limpiarFiltros = () => {
        setFiltros({})
        setPaginacion({ 
            totalPorPagina : 10,
            pagina: 1,
            sort: {
                column: "",
                type: 0
            }
        });
    }


    const SortComponent = ({ column }) => {

        let icon = <MinusOutlined />;
        
        if ( column === paginacion.sort.column ) {
            switch ( paginacion.sort.type ) {
                case 1: icon = <CaretUpOutlined />; break;
                case -1: icon = <CaretDownOutlined />; break;
            }
        }

        const configurarPaginacion = () => {

            if ( cargando ) return;

            const sort_type = sortTypes.findIndex( _type => _type === paginacion.sort.type );

            let final_sortType;
            if ( paginacion.sort.column && column === paginacion.sort.column ) {
                final_sortType = ( sort_type + 1 > sortTypes.length - 1 ) 
                    ? sortTypes[0] 
                    : sortTypes[sort_type + 1];
            } else {
                final_sortType = sortTypes[1];
            }

            const _paginacion = {
                ...paginacion,
                pagina: 1,
                sort : {
                    column: final_sortType !== 0 ? column : "",
                    type: final_sortType
                }
            }

            setPaginacion( _paginacion );
            buscarTareas( _paginacion );

        }

        return (
            <div style={{display:"inline", cursor:"pointer", textAlign:"right", width:"100%"}} onClick={configurarPaginacion} disabled={ cargando }>
                {icon}
            </div>
        )
    }

    return (
        <>
            <div style={{padding:"20px"}}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="top" >
                    { !buscadorActivo 
                        ? (
                            <>
                                {   cargando
                                    ? <div className="main-flatCard" style={{padding: "5px", marginLeft:"15px"}}>
                                        <Spin size="medium" style={{ marginLeft: 10, marginRight: 10, marginTop: 8, marginBottom: 4 }}/>
                                    </div>
                                    : <>
                                        <div className="main-flatCard" style={{padding: "5px", marginLeft:"15px"}}>
                                            <Tooltip title="search">
                                                <Button type="primary" shape="circle" onClick={() => setBuscadorActivo(true)} icon={<SearchOutlined />} size="large" style={{transition: "opacity 200ms"}}/> 
                                            </Tooltip>
                                        </div>
                                    </>
                                }

                            </>
                        )
                        : (
                            <>
                            <Col flex="1 0 25%" className="column gutter-row" >
                                <Card title="Búsqueda" className="main-flatCard" style={{ width: "100%", transition: "opacity 200ms"}} extra={<Button type="link" onClick={() => setBuscadorActivo(false)}><ArrowLeftOutlined /></Button>} >
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                        <Col className="w50 columna100">
                                            <Row style={{marginTop: 7 }}>
                                                <Col md={4}  xl={4}>CUPS</Col>
                                                <Col md={20} xl={20}>
                                                    <Input
                                                        type={"text"}
                                                        placeholder="CUPS"
                                                        allowClear
                                                        value={filtros.cups}
                                                        onChange={(ev)=> setFiltros({ ...filtros, cups : ev.target.value })}
                                                    />
                                                    
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: 20, marginBottom: 20 }}>
                                                <Col md={4}  xl={4}>Fecha creacion</Col>
                                                <Col md={20} xl={20}>
                                                    <RangePicker
                                                        value={ filtros.fechaCreacion}
                                                        onChange={( value ) => {
                                                            if ( !value ) {
                                                                const { fechaCreacion, ..._filtros } = filtros;
                                                                setFiltros( _filtros );
                                                            }
                                                            setFiltros({ ...filtros, fechaCreacion : value });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: 20 }}>
                                                <Col md={4}  xl={4}>Fecha creacion contrato</Col>
                                                <Col md={20} xl={20}>
                                                    <RangePicker
                                                        value={filtros.fechaCreacionContrato}
                                                        onChange={( value ) => {
                                                            if ( !value ) {
                                                                const { fechaCreacionContrato, ..._filtros } = filtros;
                                                                setFiltros( _filtros );
                                                            }
                                                            setFiltros({ ...filtros, fechaCreacionContrato : value });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: 7 }}>
                                                <Col md={4}  xl={4}>Subusuarios</Col> 
                                                <Col md={20}  xl={20}>
                                                <Select
                                                        className="single"
                                                        placeholder="Seleccione uno o varios"
                                                        type="primary"
                                                        style={{ width: '100%' }}
                                                        mode="multiple"
                                                        allowClear
                                                        maxTagCount='responsive'
                                                        value={filtros.subusuarios}
                                                        onChange={(ev,as,ee)=> setFiltros({ ...filtros, subusuarios : as })}
                                                >
                                                    {

                                                        listadoSubusuarios.map( ( _subusuario, _indx ) =>
                                                            <Select.Option 
                                                                key={ _indx + "_" + _subusuario.id } 
                                                                value={ _subusuario.username.toUpperCase() }
                                                            >
                                                                { `EX-${ _subusuario.username.toUpperCase() }` }
                                                            </Select.Option>
                                                        )
                                                    }
                                                </Select>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: 7 }}>
                                                <Col md={4}  xl={4}>Gestor</Col> 
                                                <Col md={20}  xl={20}>
                                                <Select
                                                        className="single"
                                                        placeholder="Seleccione uno o varios"
                                                        type="primary"
                                                        style={{ width: '100%' }}
                                                        mode="multiple"
                                                        allowClear
                                                        maxTagCount='responsive'
                                                        value={filtros.gestores}
                                                        onChange={(ev,as,ee)=> setFiltros({ ...filtros, gestores : as })}
                                                >
                                                    {

                                                        listadoGestor.map( ( _subusuario, _indx ) =>
                                                            <Select.Option 
                                                                key={ _indx + "_" + _subusuario.id } 
                                                                value={ _subusuario.username.toUpperCase() }
                                                            >
                                                                { `EX-${ _subusuario.username.toUpperCase() }` }
                                                            </Select.Option>
                                                        )
                                                    }
                                                </Select>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: 7 }}>
                                                <Col md={4}  xl={4}>Mala Praxis</Col>
                                                <Col md={20} xl={20}>
                                                    <Space direction="horizontal" style={{marginBottom:"7px"}}>
                                                        <Radio.Group 
                                                            value={filtros.praxis} 
                                                            onChange={(elem) => {

                                                                if ( elem.target.value === "5" ) {
                                                                    const { praxis, ..._filtros } = filtros;
                                                                    return setFiltros(_filtros);
                                                                }
                                                                setFiltros({ ...filtros, praxis : elem.target.value }) 
                                                            }} 
                                                            style={{width: "100%"}}
                                                        >
                                                            {

                                                            malaPraxisListado.diccionario?.map( (_praxis, _indx) => (

                                                                <Radio.Button 
                                                                    key={ _praxis.id } 
                                                                    value={_praxis.id }
                                                                >
                                                                    { _praxis.dd }
                                                                </Radio.Button>

                                                            ))
                                                            }
                                                            <Radio.Button value="5" style={{ color: 'white', backgroundColor: 'red', margin: 10, borderRadius: 5 }}> <span className="bold"> LIMPIAR FILTRO </span> </Radio.Button>
                                                        </Radio.Group>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="w50 columna100">
                                            <Row>
                                                <Col md={4}  xl={4} style={{ marginTop: 13 }}>Tipo</Col>
                                                <Col md={20} xl={20}>
                                                    <Space direction="horizontal" style={{marginBottom:"7px"}}>
                                                        <Radio.Group 
                                                            value={filtros.estado}  
                                                            onChange={(elem) => {
                                                                if ( elem.target.value === "2" ) {
                                                                    const { estado, ..._filtros } = filtros;
                                                                    return setFiltros(_filtros);
                                                                }
                                                                setFiltros({ ...filtros, estado : elem.target.value })   
                                                            }} 
                                                            style={{width: "100%"}}
                                                        >
                                                            <Radio.Button value={1}>Abierto</Radio.Button>
                                                            <Radio.Button value={2}>Cerrado</Radio.Button>
                                                            <Radio.Button value={0} style={{ color: 'white', backgroundColor: 'red', margin: 10, borderRadius: 5 }}> <span className="bold"> LIMPIAR FILTRO </span> </Radio.Button>
                                                        </Radio.Group>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}  xl={4} style={{ marginTop: 13 }}> Ultimo comentario </Col>
                                                <Col md={20} xl={20}>
                                                    <Space direction="horizontal" style={{marginBottom:"7px"}}>
                                                        <Radio.Group 
                                                            value={filtros.contestado}  
                                                            onChange={(elem) => {
                                                                if ( elem.target.value === "2" ) {
                                                                    const { estado, ..._filtros } = filtros;
                                                                    return setFiltros(_filtros);
                                                                }
                                                                setFiltros({ ...filtros, contestado : elem.target.value })   
                                                            }} 
                                                            style={{width: "100%"}}
                                                        >
                                                            <Radio.Button value={1}> GanaEnergia </Radio.Button>
                                                            <Radio.Button value={2}> Colaborador </Radio.Button>
                                                            <Radio.Button value={0} style={{ color: 'white', backgroundColor: 'red', margin: 10, borderRadius: 5 }}> <span className="bold"> LIMPIAR FILTRO </span> </Radio.Button>
                                                        </Radio.Group>
                                                    </Space>
                                                </Col>
                                            </Row>

                                            { [ "Aclara", "Aporta", "Conforme", "Deuda" ].map(( _key, _indx ) => {

                                                return <Row 
                                                    key={_indx}
                                                    style={{ marginTop: 7 }}
                                                    >
                                                    <Col md={4}  xl={4}>{ _key }</Col>
                                                    <Col md={20} xl={20}>
                                                        <Select
                                                            className="single"
                                                            placeholder="Seleccione uno o varios"
                                                            type="primary"
                                                            style={{ width: '100%' }}
                                                            maxTagCount='responsive'
                                                            value={filtros[ _key.toLowerCase() ] ?? "--"}
                                                            onChange={(ev,as,ee)=> {
                                                                if ( as.value === "" ) {
                                                                    const _filtros = { ...filtros };
                                                                    delete _filtros[ _key.toLowerCase() ];
                                                                    return setFiltros( _filtros );
                                                                }
                                                                setFiltros({ ...filtros, [ _key.toLowerCase() ] : as.value })
                                                            }}
                                                        >

                                                            <Select.Option 
                                                                key={ 1 } 
                                                                value={ 0 }
                                                            >
                                                                --
                                                            </Select.Option>

                                                            <Select.Option 
                                                                key={ 2 } 
                                                                value={ 1 }
                                                            >
                                                                Si
                                                            </Select.Option>

                                                            <Select.Option 
                                                                key={ 3 } 
                                                                value={ 2 }
                                                            >
                                                                No
                                                            </Select.Option>
                                                        
                                                        </Select>
                                                    </Col>
                                                </Row>

                                            })}

                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={24} style={{textAlign:"right"}}>
                                            <Button onClick={() => {
                                                buscarTareas({ ...paginacion, pagina: 1, totalPorPagina: 10 });
                                                setPaginacion({ 
                                                    ...paginacion,
                                                    totalPorPagina : 10,
                                                    pagina: 1
                                                });
                                            }} style={{marginRight:"5px"}}><SearchOutlined />Buscar</Button>
                                            <Button onClick={() => limpiarFiltros()}><DeleteOutlined />Limpiar</Button>
                                        </Col>
                                    </Row>
                                    <Space>
                                        
                                    </Space>
                                    
                                </Card>    
                            </Col>
                            </>
                        )
                    }
                </Row>
                <Row>
                    { !detallesActivo
                        ? (
                            <>
                                <div className="main-flatCard" style={{padding: "5px", marginTop: 30 }}>
                                    <Tooltip title="search">
                                        <Button type="primary" shape="circle" onClick={() => setDetallesActivo(true)} icon={<MenuOutlined />} size="large" style={{transition: "opacity 200ms"}}/> 
                                    </Tooltip>
                                </div>
                            </>
                        )
                        : (
                            <>
                                <Card title="Detalles" className="main-flatCard" style={{ width: "100%", marginTop: 30, transition: "opacity 200ms"}} extra={<Button type="link" onClick={() => setDetallesActivo(false)}><ArrowLeftOutlined /></Button>} >
                                    <Row>
                                        <Col style={{ flex: 1, paddingRight: 20, marginRight: 20}}>
                                            <span style={{ flex: 1, borderLeft: '7px solid red', paddingLeft: 10, marginBottom: 20 }} className="bold"> GENERALES </span>
                                            <table style={{width:"100%", borderSpacing:"20px"}}  >
                                                <thead className="cabecera">
                                                    <tr>
                                                        <td></td>
                                                        <td>Mala Praxis</td>
                                                        <td>Abiertas</td>
                                                        <td>Cerradas</td>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody key={infoTareasGeneral._id} >

                                                    {

                                                    malaPraxisListado.diccionario?.map( (_praxis, _indx) => (


                                                        <tr key={infoTareasGeneral._id+"-t"+_indx}>
                                                            <td></td>
                                                            <td>{_praxis.dd}</td>
                                                            <td>
                                                                { infoTareasGeneral[`totalTipoAbierto${_indx}`] }   
                                                            </td>
                                                            <td>
                                                                { infoTareasGeneral[`totalTipoCerrado${_indx}`] }
                                                            </td>
                                                            <td></td>
                                                        </tr>


                                                    ))
                                                    }

                                                    
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col style={{ flex: 1 }}>
                                            <span style={{ flex: 1, borderLeft: '7px solid red', paddingLeft: 10, marginBottom: 50 }} className="bold"> FILTRO </span>
                                            <table style={{width:"100%", borderSpacing:"20px"}}  >
                                                <thead className="cabecera">
                                                    <tr>
                                                        <td></td>
                                                        <td>Mala Praxis</td>
                                                        <td>Abiertas</td>
                                                        <td>Cerradas</td>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody key={"filtroTable"} >

                                                    {

                                                    malaPraxisListado.diccionario?.map( (_praxis, _indx) => (


                                                        <tr key={infoTareasGeneral._id+"-t"+_indx}>
                                                            <td></td>
                                                            <td>{_praxis.dd}</td>
                                                            <td>
                                                                { infoTareasFiltro[`totalTipoAbierto${_indx}`] }   
                                                            </td>
                                                            <td>
                                                                { infoTareasFiltro[`totalTipoCerrado${_indx}`] }
                                                            </td>
                                                            <td></td>
                                                        </tr>


                                                    ))
                                                    }

                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        )
                    }
                </Row>
                {/* RESULTADOS BUSQUEDA */}
                <Void space="2"/>
                {/* TABLA DE Tareas */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="top" > 
                    <Col flex="24 0 25%" className="column gutter-row" >
                        <Card title="Colaboradores con Mala Praxis" className="main-flatCard" style={{ width: "100%", overflow:"auto",}}>
                            <table style={{minWidth:"300px", borderSpacing:"20px"}} className="">
                                <thead>
                                    <tr className="cabecera">
                                        <td className="w1 ">
                                            Fecha de creación <SortComponent column="date_creation"/>
                                        </td>
                                        <td className="w1 ">
                                            Fecha de creación contrato <SortComponent column="contratoExt.fecha_creacion"/>
                                        </td>
                                        <td className="w1 ">
                                            Casuistica Praxis
                                        </td>
                                        <td className="w1">
                                            CUPS
                                        </td>
                                        <td className="w2">
                                            Aclara
                                        </td>
                                        <td className="w2">
                                            Aporta
                                        </td>
                                        <td className="w2">
                                            Conforme
                                        </td>
                                        <td className="w2">
                                            Deuda
                                        </td>
                                        <td className="w2">
                                            Creador <SortComponent column="contratoExt.real_agente.origen"/>
                                        </td>
                                    </tr>
                                </thead>
                                {  !cargando && <tbody >
                                        {
                                            listadoTareas.length > 0 && listadoTareas.map( (_tarea, i) => {

                                                let date = new Intl.DateTimeFormat('es-Es', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(parseInt(_tarea.date_creation+'000')) 
        
                                                let nombreCreador= _tarea.contratoExt[0]?.real_agente?.origen  ??  ""  ;
                                                nombreCreador = nombreCreador.split("ex-sub-").filter( _f => !!_f)[0] ?? ""
        
                                                return (
                                                    <tr className="fila" key={_tarea._id + "" + i}>
                                                        <td>
                                                            {date}
                                                        </td>
                                                        <td>{_tarea?.contratoExt[0]?.fecha_creacion ? new Date(_tarea?.contratoExt[0]?.fecha_creacion * 1000).toLocaleDateString(): ""}</td>
                                                        <td>
                                                            {_tarea?.casuisticaPraxis?.dd}
                                                        </td>
                                                        <td>
                                                            {(_tarea?.contrato && _tarea?.contrato?.cups) ? (
                                                                <>
                                                                    <a href={ "http://newcrm.ganaenergia.com/tareas/"+_tarea._id+"/false" } target="_blank">
                                                                        {_tarea?.contrato?.cups}
                                                                    </a>
                                                                    <CopyOutlined
                                                                        style={{ marginLeft: 10 }}
                                                                        onClick={() => { 
                                                                            message.info( "¡Texto copiado al portapapeles!" );
                                                                            navigator.clipboard.writeText(_tarea?.contrato?.cups);
                                                                        }}
                                                                    />
                                                                </>)
                                                            :
                                                                <p>-</p>
                                                            }
                                                        </td>
                                                        <td>
                                                            {(_tarea.infoCheck?.aclara) ? "Si" : "No"}
                                                        </td>
                                                        <td>
                                                            {(_tarea.infoCheck?.pruebas) ? "Si" : "No"}
                                                        </td>
                                                        <td>
                                                            {(_tarea.infoCheck?.conforme) ? "Si" : "No"}
                                                        </td>
                                                        <td>
                                                            {(_tarea.infoCheck?.deuda) ? "Si" : "No"}
                                                        </td>
                                                        <td>
                                                            {  nombreCreador }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                }
                            </table>
                            { cargando && <Spin size="large" style={{ width: '100%', marginLeft: 10, marginRight: 10, marginTop: 40, marginBottom: 20 }}/> }
                            <div style={{textAlign:"right", width:"100%", marginTop : "20px"}}>
                                <Pagination 
                                    disabled={ cargando }
                                    total={total} 
                                    showTotal={( _total ) => <div style={{ marginRight: 20 }}> <span className="bold"> TOTAL:  { _total } </span> </div> }
                                    current={paginacion.pagina}
                                    onChange={ ( pagina, registros ) => cambiarPagina({ ...paginacion, pagina, registros }) }
                                />
                            </div>
                        </Card>    
                    </Col>
                </Row>       
            </div>
        </>
    )
}