import { Col, Row } from 'antd';
import React from 'react';

export default function Pasarela({
    className = 'colAzu',
    mensaje = 'Cargando...',
    children,
    contenido = ''
}) {
    return (
        <Row>
            {(contenido === '') && (
            <Col
                className='tac'
                xs={24}
                xl={24}
            > 
                {
                    children? children : <h1 
                        className={className}
                    >
                        {mensaje}
                    </h1>
                }
                
            </Col>
            )}
            {(contenido !== '') && (
            <Col
                xs={24}
                xl={24}
            > 
                {
                    <div 
                    className={className}
                    style={{paddingTop: '3rem', textAlign: 'center'}}
                    dangerouslySetInnerHTML={{__html: contenido}}></div>
                }
                
            </Col>
            )}
        </Row>
    )
}