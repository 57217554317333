import { Col, Image, Input, Modal, Row, Switch } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cargando from '../../components/Cargando/Cargando';
import DocumentoFirma from '../../components/DocumentoFirma/DocumentoFirma';
import Pasarela from '../../components/Pasarela';
import Paso from '../../components/Paso';
import { formatTrace, getTrace } from '../../Utils/getIP';
import FirmadoCorrectamente from './Contenidos/FirmadoCorrectamente';
import InformacionContacto from './Contenidos/InformacionContacto';
import RowLogo from './Contenidos/RowLogo';
import RowPrimerPasoCabecera from './Contenidos/RowPrimerPasoCabecera';
import "./firma.scss";


import { set } from 'mobx';

export default function Firma() {

    const { token } = useParams();
    if ( !token ) window.location.href = '/error';

    const headers = useMemo(() => { return { 
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token 
        }
    }}, [token]);

    // Seter de sesionID y clientID
    const [sessionID, setSessionID] = useState('');
    // const [clientID, setClientID] = useState('');

    const url = `${process.env.REACT_APP_API_URL}`;

    

    

    const [contrato, setContrato] = useState(null);
    const [signature, setSignature] = useState(null);
    const [codigo, setCodigo] = useState(null);
    const [trace, setTrace] = useState(null);

    const [cargando, setCargando] = useState(null);
    const [statusCode, setStatusCode] = useState(null);

    const [mostrarProteccionDatos, setMostrarProteccionDatos] = useState(false);
    const [ofertasComerciales, setOfertasComerciales] = useState(false);

    
    useEffect( () => {

        (async() => {
            try {

                const _trace = await getTrace();
                if ( _trace ) setTrace(_trace);

                const { data: buscaSignature, status: __status } = await axios.get(`${url}/signature?${formatTrace(_trace)}`, headers );
                if ( __status ) setStatusCode(__status);
                
                if ( buscaSignature && buscaSignature.ok ) {
                    setSignature( buscaSignature.signature )
                    setSessionID( buscaSignature.signature.uuid )
                }
                else setSignature(false)

                const __contrato = await axios.get(`${url}/contrato`, headers )
                .then( data => data)
                .catch( error => false);
                
                setContrato(__contrato)

            } catch (error) {
                if ( error.response.data.error === "lc" ) {
                    window.location.href = '/error?status=66';
                } else {
                    const __status = (error.response && error.response.status) || null;
                    setStatusCode(__status)
                    setSignature(false);
                }                  
            }
        })();
        
    }, [token, headers, url]);

    // sera null durante el primer render, despues del useEffect se actualiza a la informacion adecauada o `false` si no hay informacion
    if ( contrato === null && signature === null ) return <Pasarela />;
    if ( signature === false ) window.location.href = `/error${ !!statusCode? `?status=${statusCode}` : '' }`;

    const signatureProcesable = signature.estado && signature.estado.id === 0;
    const signatureFirmadoPreviamente = signature.firmado && signature.firmado !== true;
    
    // Si viene el signature estaba previamente firmado sera un Object (truthy) pero si se acaba de firmar sera un true, por lo controlamos esa diferencia
    if ( !signatureProcesable && signatureFirmadoPreviamente ) return window.location.href = "https://ganaenergia.com/firma-mail-bienvenido";
    
    const signatureCancelado = signature.estado && signature.estado.id === 2 && signature.idLicitacion === null;
    if ( signatureCancelado ) return (
        <Pasarela 
            className='colRoj'
            mensaje='Este Documento ha sido Cancelado!' 
        />
    );

    const signatureExpirado = signature.estado.id == 2 && signature.idLicitacion && signature.idLicitacion !== null;
    if ( signatureExpirado ) return (
        <Pasarela 
            className=''
            contenido='<b>La firma del contrato ya no está disponible</b>
            <br>
            Las condiciones acordadas con Spock establecían un <u>plazo de 72 horas</u> para la firma del contrato. 
            <br> 
            Este plazo <b>ha expirado</b>.
            <br>
            <br>
            <b>¡Todavía puedes unirte a Gana Energía!</b> 
            <br>
            Descubre ahora nuestras tarifas <b><a href="https://ganaenergia.com/tarifas-luz" rel="noreferrer noopener" >clicando aquí</a></b> ¡El ahorro te espera!
            <br>
            '
        />
    );



    /**
     * Genera un `codigo` y envia un `sms` al telefono del cliente
     */
    const generarCodigo = async () => {

        const inputFirma = document.getElementById('inputFirma');
        // conseguimos las posiciones del elemento para scrollearlo ahi
        const posicionInputFirma = inputFirma && inputFirma.getBoundingClientRect();
        window.scrollTo({
            top: posicionInputFirma.bottom,
            behavior: 'smooth'
        });

        const { data: response } = await axios.get(`${url}/codigo?${formatTrace(trace)}`, headers );

        if ( !response.ok ) Modal.error({
            content: response.mensaje,
            closable: true,
            maskClosable: true,
            okText: 'Aceptar',
        })
        else Modal.info({
            content:'Enviando Codigo...',
            closable: true,
            maskClosable: true,
            okText: 'Aceptar',
        })
    }

    const todosDescargados = signature && signature.documentos.every(_documento => _documento.acciones === true || (_documento.acciones && _documento.acciones.length > 0));
    const todosFirmados = signature && signature.documentos.every(_documento=> _documento.firmado);

    const habilitaFirma = (
        signature 
        && todosDescargados
        && ! todosFirmados
    );

    return (<>
        
        <RowLogo />
        <RowPrimerPasoCabecera />

        <Row
            align='middle'
            justify='center'
            className='pl3 pr3'
        >
            <Col
                className='tac mt3 mb3'
                xs={24}
                lg={13}
                xl={9}
            >
                <div className='w100 aic fdc' style={{backgroundColor:'white'}}>
                    <span style={{border:'solid 2px', borderRadius:'10px'}}>
                        {
                            signature.documentos.map( (_x,indx) => {
                                const style = {};
                                if ( indx !== 0 ) style.borderTop = 'solid 1.5px';

                                return (
                                    <DocumentoFirma 
                                        key={_x.id} 
                                        style={style}
                                        trace={trace}
                                        documento={_x} 
                                        firma={token}
                                        onDescargar={(_idDocumento)=>{
                                            signature.documentos.find(__x => __x.id === _idDocumento).acciones = true;
                                            setSignature({...signature})
                                        }}
                                    />
                                )
                            })
                        }
                    </span>
                </div>
            </Col>
       
        </Row>

        <Row
            className='pl5 pr5 pt5'
        >
            <Col
                className='jcfe pt3 pb3 pr5'
                xs={16}
                sm={14}
                md={13}
                xl={7}
                xxl={8}
            > 
                <Paso indx={2} />
            </Col>

            <Col 
                className='fdc'
                xs={24}
                sm={24}
                xl={10}
                xxl={8}
            >
                <span className='tac bold fs5'> He leído y acepto los documentos descargados</span>
                <div className='jcc mt2'>
                    <Switch 
                        onChange={async (e)=>{

                            for await (const documento of signature.documentos) {

                                const body = { 
                                    idDocumento: documento.id,
                                    nombre: documento.nombre,
                                    ruta: `${documento.directorio}/${documento.nombre}`
                                };
                                
                                const headers = { 
                                    headers: { 
                                        Authorization: `Bearer ${token}`
                                    }, 
                                };
                                const {data: firmar} = await axios.put(`${url}/firma_documento?${formatTrace(trace)}`, body, headers);

                                if ( !firmar.ok ) Modal.error({
                                    content:'Error al firmar el archivo!',
                                    closable: true,
                                    maskClosable: true,
                                    okText: 'Aceptar',
                                })
                                else documento.firmado = true

                            }

                            setSignature({...signature})
                            if ( signature.documentos.every( _x => _x.firmado ) ) await generarCodigo();
                        }}
                        checked={todosFirmados}
                        disabled={!habilitaFirma}
                    />
                </div>
            </Col>

        </Row>

        <Row
            className='pl5 pr5 p5'
        >

            <Col
                className='jcfe pt3 pb3 pr5'
                xs={16}
                sm={14}
                md={13}
                xl={7}
                xxl={8}
            > 
                <Paso indx={3} />
            </Col>

            { (!todosFirmados) ? (
                <Col 
                    xs={24}
                    xl={10}
                    xxl={8}
                >
                    <h2 className={`colRoj bold fs3 tac`}>Tienes documentos pendientes por descargar y/o leer. Hazlo para poder continuar.</h2>
                </Col>
            ) : (
                <Col 
                    xs={24}
                    xl={10}
                    xxl={9}
                >
                    <h5 className='bold fs3 taj'>
                        <span className='bold'>Por último, para asegurarnos de que realmente has</span>
                        <span className='colVerCor bold'> descargado, leído y aceptado </span> 
                        <span className='bold'>los documentos sin reservas tú mismo, será necesario que </span>
                        <span className='colVerCor bold'> ingreses el código</span>
                        <span className='bold'> que te acabamos de mandar a tu </span>
                        <span className='colVerCor bold'> teléfono móvil </span>
                        <span className='bold'>vía sms para completar el proceso correctamente.</span>
                    </h5>   
                </Col>
            )}

        </Row>

        { signature.firmado === false ? (<>

            <Cargando cargando={cargando}>

                { signature.tipoContrato !== 2 && <Row
                        align='middle'
                        justify='center'
                        // className='pl5 pr5'
                    >
                        <Col 
                            className='fdc'
                            xs={24}
                            sm={24}
                            xl={10}
                            xxl={8}
                        >
                                <span className='tac bold fs3'>Quiero recibir ofertas relacionadas con los servicios de Gana Energía</span>
                                <div className='jcc mt2'>
                                    <Switch 
                                        onChange={async (e)=>{
                                            console.log('e', e)
                                            setOfertasComerciales(e)
                                        }}
                                    />
                                </div>
                                <span 
                                    className='tac fs3 mt3'
                                >
                                    Antes de darnos su consentimiento, lea la información de protección de datos <span style={{cursor:"pointer"}} onClick={() => {
                                    setMostrarProteccionDatos(true)
                                    }}>+</span>
                                </span>
                        </Col>
                    </Row> 
                }
                <Row
                    align='middle'
                    justify='center'
                    className='pl5 pr5'
                >
                    <Col
                        className='tac mb3'
                        xs={16}
                        lg={8}
                        xl={5}
                        xxl={3}
                    >
                            <Input 
                                id='inputFirma'
                                disabled={ !todosFirmados }
                                className='w80 mt3 mb3 maxw20em'
                                value={ codigo }
                                placeholder={ todosFirmados && 'Ingresa el código' }
                                style={{ border:'solid grey 2px', borderRadius:'5px' }} 
                                onChange={ (ev) => setCodigo(ev.target.value) }
                            />
                    </Col>
                </Row>
                <Row
                    align='middle'
                    justify='center'
                    className='pl5 pr5'
                >
                    <Col
                        className='tac mb3'
                        xs={16}
                        lg={8}
                        xl={5}
                        xxl={3}
                    >  
                            <Image 
                                // width={'50%'}
                                className={`${ todosFirmados ? 'cp' : 'cna'} maxw25em`}
                                src={`../img/boton-firmar-long${ (!todosFirmados )? '-disabled' : ''}.png`}
                                onClick={async ()=>{
                                    
                                    if ( !todosFirmados ) return;
                                    setCargando(true);

                                    const body = {
                                        codigo: codigo,
                                        idContrato: signature.idContrato,
                                        ofertasComerciales
                                    };

                                    try {

                                        const {data: codigoCorrecto} = await axios.put(`${url}/codigo?${formatTrace(trace)}`,body ,headers );
                                        
                                        if ( codigoCorrecto.ok ) {
                                            setSignature({...codigoCorrecto.signature, firmado: true})
                                            Modal.success({
                                                content:'El código es correcto',
                                                closable: true,
                                                maskClosable: true,
                                                okText: 'Aceptar',
                                                onOk: () => window.location.href = signature.urlOnFirmar
                                            })
                                        }
                                        
                                    } catch( error ) {
                                        
                                        setCodigo(null)

                                        Modal.error({
                                            content: 'El código es incorrecto',
                                            closable: true,
                                            maskClosable: true,
                                            okText: 'Aceptar',
                                        })
                                        
                                    }
                                    
                                    setCargando(false);

                                }}
                                preview={false}
                            />

                    </Col>
                </Row>

                <Row
                    align='middle'
                    justify='center'
                    className='pl5 pr5'
                    >
                    <Col
                        className='tac mt3 mb3'
                        xs={24}
                        lg={16}
                        xl={20}
                        >
                        <div className='bold pb3 '>
                            ¿No has recibido tu código vía sms en tu móvil? 
                        </div>
                        <Image 
                            className={`${ todosFirmados ? 'cp' : 'cna'} maxw12em`}
                            src={`../img/boton-solicitar${ (!todosFirmados )? '-disabled' : ''}.png`}
                            onClick={async ()=>{
                                
                                setCargando(true);
                                
                                const todosFirmados = signature.documentos.every(documento => documento.firmado);
                                if ( todosFirmados ) await generarCodigo()
                                
                                setCargando(false);
                                
                            }}
                            preview={false}
                            />
                            

                    </Col>
                </Row>
                <Modal 
                    visible={mostrarProteccionDatos}
                    style={{ top: 20 }}
                    onCancel={() => setMostrarProteccionDatos(false)}
                    onOk={() => setMostrarProteccionDatos(false)}
                    title='Protección de datos'
                    footer={null}
                    
                >
                    <div className='' onClick={() => setMostrarProteccionDatos(false)}>
                        <div className='mt2 fs3' style={{}}>
                            <span className='bold mb5'>Información básica sobre protección de datos</span><br/><br/>
                            <span className='jcl'><b>Responsable</b>: GAOLANIA SERVICIOS S.L. (GANA ENERGÍA).</span><br/>
                            <span style={{textJustify:"justify", textJustify:"inter-word"}}><b>Dirección postal:</b> C/ Colón 60, piso 5, 46004 de Valencia. España.</span><br/>
                            <span style={{textJustify:"justify", textJustify:"inter-word"}}><b>Finalidad:</b> Sus datos serán usados para contactarle y atender su solicitud de ayuda o de información de nuestros servicios.</span><br/>
                            <span style={{textJustify:"justify", textJustify:"inter-word"}}><b>Legitimación:</b> Sus datos serán tratados solo con su consentimiento, al marcar la casilla mostrada en este formulario.</span><br/>
                            <span style={{textJustify:"justify", textJustify:"inter-word"}}><b>Destinatarios:</b> Sus datos no serán cedidos a terceros.</span><br/>
                            <span style={{textJustify:"justify", textJustify:"inter-word"}}><b>Derechos:</b> Tiene derecho a acceder a sus datos, rectificarlos, eliminarlos y oponerse o limitar su tratamiento, dirigiéndose a nuestra dirección postal o a info@ganaenergia.com.</span><br/>
                            <span style={{textJustify:"justify", textJustify:"inter-word"}}><b>Más información:</b> Dispone de más información en nuestra Política de solicitudes de empleo.</span><br/>
                            <span style={{textJustify:"justify", textJustify:"inter-word"}}><b>Delegado de protección de datos:</b> Si tiene dudas sobre como trataremos sus datos o quiere trasladar alguna sugerencia o queja, contacte a nuestro DPD en info@businessadapter.es o en el Formulario de atención al interesado.</span><br/>
                        </div>
                    </div>
                </Modal>
            </Cargando>

        </>) : (
            <FirmadoCorrectamente />
        )
        }

        <InformacionContacto />
    
    </>);
}