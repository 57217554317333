import { Image, Space } from "antd";
import { useEffect } from "react";
import Card from "../../components/Card/Card";
import { mbxGlobal } from "../../MobX/root";


export default function Logout() {

    useEffect( () => {
        mbxGlobal.user = null;
		localStorage.removeItem("user");
		localStorage.removeItem("global");
    },[])

    return(
        <>
            <div
                className="main-login fdc jcc aic"
            >
                <Card
                    childrenClassName="jcc aic"
                    width={"90%"}
                    liftOnHover
                >   
                    <Space direction="vertical" size="middle">

                        <Image
                            src="img/logo.4b9fe3b8.svg"
                            w="100%"
                            preview={false}
                            /> 
                        <h1>Has cerrado la sesión correctamente</h1>
                        <p>Si quires volver a entrar pulsa <a href="/login">aquí</a></p>
                    </Space>
                </Card>
            </div>
        </>
    )
}