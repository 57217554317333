import React from 'react';

export default function Paso({indx, style={
    width:'40px',
    height:'40px',
    border:'solid 3px var(--colVerCor)',
    borderRadius:'100%',
    marginTop:'-2em',
}}) {
    return (<span 
        className='aic paso'
        style={style}
    >
        <span className='tac w100 bolder colVerCor fs5'>{indx}</span>
    </span>)
}