
import { Col, Image, Row } from 'antd';
import React from 'react';


export default function RowLogo(){
    return <Row
        align='middle'
        justify='center'
        className='pt5 pl5 pr5'
    >
        <Col
            className='tac'
            xs={18} // mobile L,M S 
            md={15} // tablet,
            lg={10} // laptop
            xl={6} // laptop L
            xxl={5} // 4K
        >
            <Image
                // className='w100'
                id='logo'
                src="../img/logo.4b9fe3b8.svg" 
                preview={false} 
            />
        </Col>

    </Row>
}