import { Button, Card, Col, Input, Modal, Radio, Row, Space, Tooltip, DatePicker, Divider, Pagination, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import "./praxis.scss"
import Void from "../../atomicComponents/Void"
import apiRequest from "../../Utils/apiRequest"
import '../../components/FlatCard/flatCard.scss'
import { CaretUpOutlined, CaretDownOutlined, MinusOutlined, SearchOutlined, ArrowLeftOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom"
import getDateRanges from "../../Utils/getDateRanges";

export default function Praxis() {
    

    const { idColaborador } = useParams();

    const [listaTareas, setListaTareas] = useState([])
    const [listaFirmasInicial, setListaFirmasInicial] = useState([])
    const [fechaAlta, setFechaAlta] = useState("")
    const [colaboradorFiltro,setColaboradorFiltro] = useState("")
    const [colaboradorFiltroTexto,setColaboradorFiltroTexto] = useState([])
    const [colaboradorFiltroCerradas, setColaboradorFiltroCerradas] = useState("");
    const [colaboradorFiltroAbiertas, setColaboradorFiltroAbiertas] = useState("");
    const [listaAccesosTareas, setListaAccesosTareas] = useState([])
    const [verModalAccesos, setVerModalAccesos] = useState(false)
    const [filtroAbiertas, setFiltroAbiertas] = useState("")
    const [filtroCerradas, setFiltroCerradas] = useState("")
    const [sortTablaTipo, setSortTablaTipo] = useState("")
    const [sortTablaColumna, setSortTablaColumna] = useState("")


    const [orden, setOrden] = useState("")
    const [sort, setSort] = useState({
        column : "",
        order : ""
    })

    const [cargando, setCargando] = useState(false);
    const [mostrarBuquedaTareas, setMostrarBuquedaTareas] = useState(false);

    const [paginaActualTareas, setPaginaActualTareas] = useState(1)
    const [registrosPorPaginaTareas, setRegistrosPorPaginaTareas] = useState(10)

    const [totalRegistrosTareas, setTotalRegistrosTareas] = useState(0)

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [textoBusquedaTareas, setTextoBusquedaTareas] = useState("")
    const [colaboradoresListado, setColaboradoresListado] = useState( [] );
    const [filtroPraxis, setFiltroPraxis] = useState([])
    const [malaPraxisListado, setMalaPraxisListado] = useState( [] );
    
    useEffect(() => {
        
        (async() => {

            setCargando( true );

            let urlTasks = "/malaPraxis?pagina="+paginaActualTareas+"&registros="+registrosPorPaginaTareas+"&malaPraxis="+1;
            if ( idColaborador ){
                urlTasks += "&idUser="+idColaborador;
            }
            // Obtenemos el listado de colaboradores con los valores por defecto
            let tareas = await apiRequest("get", urlTasks , {}, true);

            if(tareas.datos.length > 0){
                // asignamos los datos a la tabla
                setListaTareas(tareas.datos)

                setTotalRegistrosTareas(tareas.total);
                setPaginaActualTareas(1)

                if(textoBusquedaTareas !== "")
                    setTextoBusquedaTareas(textoBusquedaTareas)
                else 
                    setTextoBusquedaTareas("Todo")
            }

            // Obtenemos el listado de colaboradores con los valores por defecto
            let listadoColaboradores = await apiRequest("get", '/colaboradores' , {}, true);
            let listadoSubusuarios = await apiRequest("get", '/subusuarios' , {}, true);
            let diccionarioMalaPraxis = await apiRequest("get", '/diccionario?id=casuisticasMalaPraxis' , {}, true);

            if ( listadoColaboradores ) setColaboradoresListado( listadoColaboradores );
            if ( diccionarioMalaPraxis ) setMalaPraxisListado( diccionarioMalaPraxis );

            if ( idColaborador ) {
                setColaboradorFiltro([idColaborador]);
                const colabEncontrado = listadoColaboradores.colaboradores.find((c) => c.id_usuario === Number(idColaborador) )
                if ( colabEncontrado ) setColaboradorFiltroTexto([colabEncontrado.razon_social])
            }

            setCargando( false );
            
        })();
        
    },[])

    useEffect(() => {
        
        let listaFirmados = [...listaTareas];

        switch (sort.order) {
            case "ascend":
                listaFirmados = _.orderBy(listaFirmados, [sort.column],['asc'])
                break;
            case "descend":
                listaFirmados = _.orderBy(listaFirmados, [sort.column],['desc'])        
                break;
        
            default:
                listaFirmados = listaFirmasInicial;
                break;
        }
        
        setListaTareas(listaFirmados)


    },[sort])

    const buscarTareas = async ( _paginacion = null ) => {

        setCargando( true );

        let element = document.getElementById("table");
        element.classList.remove("hidden");
        let getQueryTareas = []
        
        let fechaIniTareas = "";
        let fechaFinTareas = "";

        if(fechaAlta && fechaAlta.length > 0) {
            fechaIniTareas = fechaAlta[0]?.format("YYYY-MM-DD");
            fechaFinTareas = fechaAlta[1]?.format("YYYY-MM-DD");
            getQueryTareas.push("fcdesde="+fechaIniTareas+"&fchasta="+fechaFinTareas)
        }

        if(  _paginacion && ( _paginacion.pagina || paginaActualTareas ) ) {
            getQueryTareas.push("pagina="+( _paginacion.pagina ?? paginaActualTareas ) )
        }
        if( _paginacion && ( _paginacion.registros || registrosPorPaginaTareas ) ) {
            getQueryTareas.push("registros="+ ( _paginacion.registros ?? registrosPorPaginaTareas ) )
        }

        if(colaboradorFiltro) getQueryTareas.push("idUser="+colaboradorFiltro)

        if(filtroPraxis) getQueryTareas.push("casuisticaPraxis="+filtroPraxis)

        if(filtroAbiertas) getQueryTareas.push("abiertas="+filtroAbiertas)
        if(filtroCerradas) getQueryTareas.push("cerradas="+filtroCerradas)

        if( _paginacion && ( _paginacion.sortColumn || sortTablaColumna ) ) getQueryTareas.push("columna="+( _paginacion.sortColumn ?? sortTablaColumna ) )
        if( _paginacion && ( _paginacion.sortTipo || sortTablaTipo ) ) getQueryTareas.push("tipo="+( _paginacion.sortTipo ?? sortTablaTipo ) )

        let queryTareas = (getQueryTareas.length > 0 ) ?  "?" + getQueryTareas.join("&") : "";

        let textoBusqueda = (getQueryTareas.length > 0 ) ?  getQueryTareas.join("<br/>") : "";
        textoBusqueda = montarTextoBusqueda(getQueryTareas);
        setTextoBusquedaTareas(textoBusquedaTareas)

        let tareas = await apiRequest("get", "/malaPraxis" + queryTareas, {}, true);
        if(tareas.datos.length > 0){
            // asignamos los datos a la tabla
            setListaTareas(tareas.datos)
            setTotalRegistrosTareas(tareas.total);
        } else {
            // Limpiamos lod datos
            setListaTareas([])
            setTotalRegistrosTareas(0);
            setPaginaActualTareas(1)
    
            setListaTareas([])
            
            setListaAccesosTareas([])
        }

        setCargando( false );

    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async function verTareasAbCe(colaborador,estado,praxis) { 

        setListaAccesosTareas([])
        let firma =await apiRequest("get", "/tareasColaboradorPraxis?colaborador="+colaborador+"&estado="+estado+"&praxis="+praxis, {}, true);
        if(firma && firma.tareas.length > 0){
            setListaAccesosTareas(firma.tareas)
        }
        setVerModalAccesos(true)
    }

    const CambiarPaginacion = ( 
        pagina = paginaActualTareas, 
        registros = registrosPorPaginaTareas,
        sortTipo = sortTablaTipo,
        sortColumn = sortTablaColumna,
    ) => {
        
        setPaginaActualTareas(pagina);
        setRegistrosPorPaginaTareas(registros);
        setSortTablaTipo(sortTipo);
        setSortTablaColumna(sortColumn);

        buscarTareas({ pagina, registros, sortTipo, sortColumn });

    }

    const handleCancel = () => {
        setVerModalAccesos(false)
    }

    const montarTextoBusqueda = (query) => {
        // Recorremos la query
        let texto = "";
        let accion = ""
        query.forEach(element => {  
            let linea = element.split("=");
            switch (linea.length) {
                case 2:
                    if(linea[0] !== "pagina" && linea[0] !== "registros"){
                        accion = "es"
                        let campo = linea[0]
                        let valor = linea[1]
                        switch (campo) {
                            case "origen":
                                switch (parseInt(valor)) {
                                    case 0:
                                        valor = "Interno"
                                        break;
                                    case 1:
                                        valor = "Colaboradores"
                                        break;
                                    case 2:
                                        valor = "Agencia"
                                        break;
                                    case 3:
                                        valor = "Web"
                                        break;
                                    default:
                                        valor = "Todos"
                                        break;
                                }
                                break;
                        
                            case "telefono":
                                campo = "teléfono"
                                accion = "contiene"
                                break;
                            
                            case "email":
                                accion = "contiene"
                                break;

                            case "contrato":
                                campo = "Id del contrato"
                                break;
                        
                            default:
                                break;
                        }
                        
                        texto += "- " + capitalizeFirstLetter(campo) + " " + accion + " <b>" + valor + "</b><br/>"
                    }
                    break;
                
                case 3:

                    linea = element.split("&");
                    
                    // fc... => fecha_creacion
                    // ff... => fecha_firma

                    let fechaDesde = linea[0].split("=")
                    let fechaHasta = linea[1].split("=")
                    
                    let campoDesde = fechaDesde[0]
                    let valorDesde = fechaDesde[1]
                    
                    let campoHasta = fechaHasta[0]
                    let valorHasta = fechaHasta[1]
                    
                    let stringFecha = ""

                    switch (campoDesde) {
                        case "fcdesde":
                            stringFecha = "Fecha de creación :  desde " + valorDesde + "</b> hasta " + valorHasta +"<br/>"
                            break;
                        case "ffdesde":
                            stringFecha = "Fecha de firma :  desde " + valorDesde + "</b> hasta " + valorHasta +"<br/>"
                            break;
                    
                        default:
                            break;
                    }
                    
                    texto += "- " + stringFecha

                    switch (campoHasta) {
                        case "fchasta":
                            
                            break;
                        case "ffhasta":
                            
                            break;
                    
                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        });

        return texto;
    }

    const SortComponent = (props) => {
        
        let columna = props.sortColumn
        
        const ordenar = () => {

            if ( cargando ) return;

            if ( sort.column === columna ) {

                if(orden === ""){   
                    setOrden("ascend")
                    setSort({
                        column : columna,
                        order : "ascend"
                    })
                    CambiarPaginacion( null, null, "ascend", columna );
                } else if( orden === "ascend"){
                    setOrden("descend")
                    setSort({
                        column : columna,
                        order : "ascend"
                    })
                    CambiarPaginacion( null, null, "descend", columna );
                } else if( orden === "descend"){
                    setOrden("")
                    setSort({
                        column : columna,
                        order : ""
                    })
                    CambiarPaginacion( null, null, "", columna );
                }

            } else {
                setOrden("ascend")
                setSort({
                    column : columna,
                    order : "ascend"
                })
                CambiarPaginacion( null, null, "ascend", columna );
            }
        }
        
        let icon = <MinusOutlined />

        if(sort.column === columna) {
            switch (orden) {
                case "ascend":
                    icon = <CaretUpOutlined />
                    break;
            
                case "descend":
                    icon = <CaretDownOutlined />
                    break;
                default:
                    break;
            }
        }

        return (
            <div style={{display:"inline", cursor:"pointer", textAlign:"right", width:"100%"}} onClick={ordenar}>
                {icon}
            </div>
        )
    }
    
    const showListTareas = () => {
        setMostrarBuquedaTareas(!mostrarBuquedaTareas)
    }

    const limpiarDatosTareas = () => {
        setFechaAlta("")
        setFiltroPraxis("")
        setColaboradorFiltro("")
        setColaboradorFiltroTexto([])
        setListaFirmasInicial([])
        setFiltroAbiertas("")
        setFiltroCerradas("")
        setColaboradorFiltroAbiertas("");
        setColaboradorFiltroCerradas("");
    }

    const buscarColaboradores = (valores) => {
        let arrayValores = []
        let arrayTexto = []
        valores.forEach(_valor =>{
            arrayValores.push(_valor.key)
            arrayTexto.push(_valor.value)
        })
        setColaboradorFiltroTexto(arrayTexto)
        setColaboradorFiltro(arrayValores)
    
    }

    const buttonVerTareas = async(idUsuario) => {
        var element = document.getElementById("id"+idUsuario);
        if(element.classList.contains("hidden")){
            element.classList.remove("hidden");
        }else{
            element.classList.add("hidden");
        }
    }

    const busquedasAbCe = (valor,tipo) => {
        if(tipo === 0){
            setColaboradorFiltroAbiertas(valor)
            setFiltroAbiertas(valor);
            setFiltroCerradas("")
            setColaboradorFiltroCerradas("")
        }
        if(tipo === 1){
            setColaboradorFiltroCerradas(valor)
            setFiltroCerradas(valor);
            setFiltroAbiertas("");
            setColaboradorFiltroAbiertas("")
        }
    }

    return(
        <>
            <div style={{padding:"20px"}}>
                {/* PANEL DE BUSQUEDA */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="top" >
                    { !mostrarBuquedaTareas && colaboradoresListado.colaboradores &&
                        <>
                            <div className="main-flatCard" style={{padding: "5px", marginLeft:"15px"}}>
                                <Tooltip title="search">
                                    <Button type="primary" shape="circle" onClick={showListTareas} icon={<SearchOutlined />} size="large" style={{transition: "opacity 200ms"}}/> 
                                </Tooltip>
                            </div>
                            <div style={{marginLeft : "15px"}}>
                                Búsqueda : <div dangerouslySetInnerHTML={{__html: textoBusqueda}}></div>
                            </div>
                        </>
                        
                    }
                    {   !mostrarBuquedaTareas && !colaboradoresListado.colaboradores &&
                        <>
                            <div className="main-flatCard" style={{padding: "5px", marginLeft:"15px"}}>
                                <Spin size="medium" style={{ marginLeft: 10, marginRight: 10, marginTop: 8, marginBottom: 4 }}/>
                            </div>
                        </>
                    }
                    { mostrarBuquedaTareas && (
                        <>
                        <Col flex="1 0 25%" className="column gutter-row" >
                            <Card title="Búsqueda" className="main-flatCard" style={{ width: "100%", transition: "opacity 200ms"}} extra={<Button type="link" onClick={showListTareas}><ArrowLeftOutlined /></Button>} >
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                    <Col className="w50 columna100">
                                        <Row style={{marginTop:"7px"}}>
                                            <Col md={4}  xl={4}>Colaborador</Col> 
                                            <Col md={20}  xl={20}>
                                            <Select
                                                    className="single"
								                    placeholder="Seleccione uno o varios"
                                                    type="primary"
                                                    style={{ width: '100%' }}
                                                    mode="multiple"
                                                    allowClear
                                                    maxTagCount='responsive'
                                                    value={colaboradorFiltroTexto}
                                                    onChange={(ev,as,ee)=> buscarColaboradores(as)}
                                            >
                                                {

                                                    colaboradoresListado.colaboradores.map( (_email, _indx) => (
                                                
                                                        <Select.Option 
                                                            key={ _email.id_usuario } 
                                                            value={_email.razon_social.toUpperCase() }
                                                        >
                                                            { _email.razon_social.toUpperCase() }
                                                        </Select.Option>
                                                    
                                                    ))
                                                }
                                            </Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}  xl={4}>Mala Praxis</Col>
                                            <Col md={20} xl={20}>
                                                <Space direction="horizontal" style={{marginBottom:"7px"}}>
                                                    <Radio.Group value={filtroPraxis}  onChange={(elem) => {
                                                        setFiltroPraxis(elem.target.value)   
                                                    }} style={{width: "100%"}}>
                                                        {

                                                        malaPraxisListado.diccionario.map( (_praxis, _indx) => (

                                                            <Radio.Button 
                                                                key={ _praxis.id } 
                                                                value={_praxis.id }
                                                            >
                                                                { _praxis.dd }
                                                            </Radio.Button>

                                                        ))
                                                        }
                                                    </Radio.Group>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:"7px"}}>
                                            <Col md={4} xl={4}>Abiertas</Col> 
                                            <Col md={20} xl={20}>
                                            <Input
                                                    className="single"
                                                    type={"text"}
                                                    placeholder="Abiertas"
                                                    allowClear
                                                    value={colaboradorFiltroAbiertas}
                                                    onChange={(ev)=> busquedasAbCe(ev.target.value,0)}
                                            >
                                            </Input>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:"7px"}}>
                                            <Col md={4} xl={4}>Cerradas</Col> 
                                            <Col md={20} xl={20}>
                                            <Input
                                                    className="single"
                                                    placeholder="Cerradas"
                                                    allowClear
                                                    value={colaboradorFiltroCerradas}
                                                    onChange={(ev)=> busquedasAbCe(ev.target.value,1)}
                                            >
                                            </Input>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs={24} style={{textAlign:"right"}}>
                                        <Button onClick={() => buscarTareas()} style={{marginRight:"5px"}}><SearchOutlined />Buscar</Button>
                                        <Button onClick={() => limpiarDatosTareas()}><DeleteOutlined />Limpiar</Button>
                                    </Col>
                                </Row>
                                <Space>
                                    
                                </Space>
                                
                            </Card>    
                        </Col>
                        </>
                    )}
                </Row>
                {/* RESULTADOS BUSQUEDA */}
                <Void space="2"/>
                {/* TABLA DE Tareas */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="top" > 
                    <Col flex="24 0 25%" className="column gutter-row" >
                        <Card title="Colaboradores con Mala Praxis" className="main-flatCard" style={{ width: "100%", overflow:"auto",}}>
                            <table style={{minWidth:"600px", borderSpacing:"20px"}} id="table" className="">
                                <thead>
                                    <tr className="cabecera">
                                        <td className="w1">
                                            Nombre Colaborador 
                                        </td>
                                        <td className="w1">
                                            Username <SortComponent sortColumn="_id"></SortComponent>
                                        </td>
                                        <td className="w2">
                                            Abiertas <SortComponent sortColumn="abiertas"></SortComponent>
                                        </td>
                                        <td className="w1">
                                            Cerradas <SortComponent sortColumn="cerradas"></SortComponent>
                                        </td>
                                        <td className="w1">
                                            Acciones
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                {

                                    !cargando && listaTareas.length > 0 && listaTareas.map( (_tareas, _indx) => {;

                                        let controlTareas = true
                                        
                                        if(_tareas.abiertas > 0 || _tareas.cerradas > 0){
                                            controlTareas = false
                                        }
                                        
                                        return (
                                            <React.Fragment key={_indx}>
                                            <tr className={"fila" }  key={_indx}>
                                                <td>
                                                    <a onClick={() => window.open(`/praxisSub?id=${ _tareas._id }`) }> {_tareas.nombre[0] } </a>
                                                </td>
                                                <td>
                                                    {_tareas.usuario}
                                                </td>
                                                <td>
                                                    {_tareas.abiertas}
                                                </td> 
                                                <td>
                                                    {_tareas.cerradas}
                                                </td>
                                                <td>
                                                <Tooltip title={"Ver Detalles"}>
                                                    <a
                                                        disabled={controlTareas}
                                                        onClick={()=> buttonVerTareas(_tareas._id)}
                                                    >
                                                        <EyeOutlined 
                                                            style={(!controlTareas) ? { fontSize: '16px', color: 'dodgerblue' } : {  fontSize: '16px', color: 'lightgrey' }} 
                                                        />
                                                    </a>
                                                </Tooltip>
                                                </td>                
                                              
                                            </tr>
                                             
                                            <tr className={"fila hidden subFila" } key={_tareas.id_usuario} id={"id"+_tareas._id} >
                                                <td colSpan='13'>
                                                    <h5> Detalles</h5>
                                                    <table style={{width:"100%", borderSpacing:"20px"}} className="">
                                                        <thead className="cabecera">
                                                            <tr>
                                                                <td></td>
                                                                <td>Mala Praxis</td>
                                                                <td>Abiertas</td>
                                                                <td>Cerradas</td>
                                                                <td></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody key={_tareas._id} >

                                                            {

                                                            malaPraxisListado.diccionario.map( (_praxis, _indx) => (

                                                            
                                                                <tr key={_tareas._id+"-t"+_indx}>
                                                                    <td></td>
                                                                    <td>{_praxis.dd}</td>
                                                                    <td>
                                                                        <Tooltip title={"Ver Detalles"}>
                                                                        <a
                                                                            disabled={(_tareas[`totalTipoAbierto${_indx}`] > 0) ? false : true}
                                                                            onClick={()=> verTareasAbCe(_tareas._id,1,_indx)}
                                                                        >    
                                                                            {_tareas[`totalTipoAbierto${_indx}`]}
                                                                        </a>   
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td>
                                                                        <Tooltip title={"Ver Detalles"}>
                                                                        <a
                                                                            disabled={(_tareas[`totalTipoCerrado${_indx}`] > 0) ? false : true}
                                                                            onClick={()=> verTareasAbCe(_tareas._id,2,_indx)}
                                                                        >    
                                                                            {_tareas[`totalTipoCerrado${_indx}`] }
                                                                        </a>   
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>


                                                            ))
                                                            }
                                                            
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            </React.Fragment>
                                        )
                                    })
                                    
                                }
                                </tbody>
                            </table>
                            { cargando && <div style={{ width : '100%', textAlign: 'center', marginTop: 20 }}> <Spin size="large"/> </div> }
                            <div style={{textAlign:"right", width:"100%", marginTop : "20px"}}>
                                <Pagination 
                                    total={totalRegistrosTareas} 
                                    current={paginaActualTareas}
                                    onChange={(pagina, registros)=>{
                                        CambiarPaginacion( pagina, registros );
                                    }}
                                />
                            </div>
                        </Card>    
                    </Col>
                </Row>

                {/* MODAL TAREAS */}
                <Modal title="Tareas con mala praxis" visible={verModalAccesos} onCancel={handleCancel} width={1000} height={300}
                    footer={[
                        <Button 
                            onClick={handleCancel}
                        >
                        Cerrar
                        </Button>
                    ]}
                >
                    <div style={{height:"600px",overflow:"auto",width:"100%"}}>
                        <table style={{minWidth:"300px", borderSpacing:"20px"}} className="">
                            <thead>
                                <tr className="cabecera">
                                    <td className="w1 ">
                                        Fecha de creación
                                    </td>
                                    <td className="w1 ">
                                        Fecha de creación contrato
                                    </td>
                                    <td className="w1">
                                        CUPS 
                                    </td>
                                    <td className="w2">
                                        Aclara
                                    </td>
                                    <td className="w2">
                                        Aporta
                                    </td>
                                    <td className="w2">
                                        Conforme
                                    </td>
                                    <td className="w2">
                                        Deuda
                                    </td>
                                    <td className="w2">
                                        Creador
                                    </td>
                                </tr>
                            </thead>
                            <tbody >
                            {
                                listaAccesosTareas && listaAccesosTareas.map( (_acceso, i) => {

                                    let date = new Intl.DateTimeFormat('es-Es', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(parseInt(_acceso.date_creation+'000')) 

                                    let nombreCreador = ( _acceso.contratoExt.length && _acceso.contratoExt[0].real_agente?.origen )  ??  "";
                                    nombreCreador = ( nombreCreador && nombreCreador.split("ex-sub-").filter( _f => !!_f)[0] ) ?? "";

                                    return (
                                        <tr className="fila" key={_acceso._id + "" + i}>
                                            <td>
                                                {date}
                                            </td>
                                            <td>{ ( _acceso.contratoExt.length && _acceso?.contratoExt[0]?.fecha_creacion ) ? new Date(_acceso?.contratoExt[0]?.fecha_creacion * 1000).toLocaleDateString(): ""}</td>
                                            <td>
                                                { _acceso.contrato && _acceso.contrato.cups 
                                                    ? (
                                                        <Button 
                                                            type="link" 
                                                            href={ "https://newcrm.ganaenergia.com/tareas/"+_acceso._id+"/false" }
                                                            target="_blank"
                                                        >
                                                            {_acceso.contrato?.cups}
                                                        </Button>
                                                    )
                                                    : <span className="jcc bold"> Sin contrato asociado </span>

                                                }

                                                { _acceso.contrato && !_acceso.contrato.cups && (
                                                    <p>-</p>
                                                )}
                                            </td>
                                            <td>
                                                {(_acceso.infoCheck?.aclara) ? "Si" : "No"}
                                            </td>
                                            <td>
                                                {(_acceso.infoCheck?.pruebas) ? "Si" : "No"}
                                            </td>
                                            <td>
                                                {(_acceso.infoCheck?.conforme) ? "Si" : "No"}
                                            </td>
                                            <td>
                                                {(_acceso.infoCheck?.deuda) ? "Si" : "No"}
                                            </td>
                                            <td>
                                                {  nombreCreador 
                                                    ? nombreCreador  
                                                    : <span className="jcc bold"> Sin colaborador asociado </span>
                                                }
                                            </td>
                                        </tr>
                                    )

                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </Modal>
            </div>
        </>
    )
}