import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Pasarela from "../../components/Pasarela";

export default function Redirect(){
    
    const { id: nanoid } = useParams();
    const url = `${process.env.REACT_APP_API_URL}`;
    
    useEffect(() => {
        
        (async() => {
            
            try {
                
                const {data: traduccion } = await axios.get(`${url}/traduceRedirect/${nanoid}`, { headers: { 'Content-Type': 'application/json'} } );
                if ( traduccion && traduccion.ok ) window.location.href = `/firma/${traduccion.token}`
                else throw new Error( traduccion.error );

            } catch(e) {
                if ( e.response.data.error === "lc" ) {
                    window.location.href = '/error?status=66';
                } else {
                    window.location.href = `/error`
                }    
                
            }
            
        })();
        
    } , [nanoid]);

    return <Pasarela mensaje = "Redirigiendo..." />
}