import Pasarela from "../../components/Pasarela";


export default function Error(){
    const params = new URLSearchParams(window.location.search)
    const status = params.get('status') || ""
    return (
        <Pasarela 
            mensaje={`Error!${status}`}
            className="colRoj"
        />
    )

}