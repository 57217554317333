import { LockOutlined, UserOutlined, BarcodeOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validame } from "validame";

import Image from "../../atomicComponents/Image";
import Status from "../../atomicComponents/Status";
import Void from "../../atomicComponents/Void";
import Card from "../../components/Card/Card";

import { mbxGlobal } from "../../MobX/root";

import apiRequest from "../../Utils/apiRequest";
import "./login.scss";

/** Guarda los datos de un usuario tras un login correcto */
const guardaUser = (res) => {
  mbxGlobal.user = res;
};

export default function Login() {
  const history = useNavigate();

  // ***********************************************************
  // Estados
  // ***********************************************************
  const [username, setUsername] = useState(null);
  const [pass, setPass] = useState(null);
  const [mantenermeConectado, setMantenermeConectado] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [guardarLogin, setGuardarLogin] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [qrCode, setQrCode] = useState(null); // Para almacenar el código QR
  const [secretExists, setSecretExists] = useState(false); // Para manejar si el secreto ya existe
  const [showOTPInput, setShowOTPInput] = useState(false); // Para manejar cuándo mostrar el input OTP
  const [otpError, setOtpError] = useState(null); // Para manejar los errores del OTP

  // ***********************************************************
  // Funciones
  // ***********************************************************
  const pulsaLogin = async () => {
    setError(null);

    // ***********************************************************
    // Validación
    // ***********************************************************
    let newError = {};

    let errUsername = validame(username, {
      req: 2,
    });
    if (errUsername) newError.username = errUsername;

    let errPass = validame(pass, {
      req: 2,
    });
    if (errPass) newError.pass = errPass;

    setError(newError);
    if (Object.values(newError).some((_x) => _x !== "")) return;

    // ***********************************************************
    // Llamada API
    // ***********************************************************
    setLoading(true);

    const res = await apiRequest(
      "post",
      process.env.REACT_APP_AUTH_URL + "/crm",
      { username: username, password: pass },
      false,
      { ignoreBaseUrl: true }
    );

    setLoading(false);

    if (res._error) {
      let msg;

      switch (res._status) {
        case 0:
          msg = res._statusText;
          break;
        case 401:
          msg = "Usuario o contraseña inválidos.";
          break;
        default:
          msg = "Ha ocurrido un error";
      }

      setError({
        general: msg,
      });
      return;
    }

    const token = res.token;

    if (token) {
      guardaUser(res);
      // Redirijo
      history("/dashboard");
      // La redirección no se procesa sino se pone la siguiente orden
      // entiendo que esto debe ser un bug
      return history(0);
    }

    if (res.qrCodeURL) {
      // Si el usuario pertenece al grupo 4 y se genera un QR
      setGuardarLogin(res);
      setQrCode(res.qrCodeURL);
    } else if (res.secretExists) {
      // Si el usuario ya tiene un secreto OTP
      setSecretExists(true);
      setGuardarLogin(res);
    } else if (res.id) {
      setGuardarLogin(res);
    } else {
      setError({ general: res.message });
    }
  };

  const enviarCodigo = async () => {
    const respuestaCodigo = await apiRequest(
      "get",
      process.env.REACT_APP_AUTH_URL + `/codigoLogin?username=${username}&codigo=${codigo}`,
      null,
      false,
      { ignoreBaseUrl: true }
    );
    if (respuestaCodigo.ok) {
      guardaUser({ ...guardarLogin, token: respuestaCodigo.token });
      history("/dashboard");
      // entiendo que esto debe ser un bug
      return history(0);
    } else {
      setError({ codigo: "Código incorrecto" });
    }
  };

  const verificarOTP = async () => {
    const otpVerification = await apiRequest(
      "post",
      process.env.REACT_APP_AUTH_URL + "/verify-otp",
      { username: username, otp: codigo },
      false,
      { ignoreBaseUrl: true }
    );

    if (otpVerification.ok) {
      guardaUser({ ...guardarLogin, token: otpVerification.token });
      history("/dashboard");
      // entiendo que esto debe ser un bug
      return history(0);
    } else {
      setOtpError(otpVerification.message);
    }
  };

  const pulsaTeclaPassword = (ev) => {
    if (ev.key === "Enter") {
      pulsaLogin();
    }
  };

  return (
    <div className="main-login fdc jcc aic p10">
      <Card childrenClassName="jcc aic" width={"100%"} margin={"0"} liftOnHover>
        <div className="fdc jcc aic">
          <Void space={2} />

          <Image
            src="img/logo.4b9fe3b8.svg"
            w="75%"
            style={{
              marginBottom: "-1.2em",
            }}
          />

          <p
            className="areaClientes bold fs4 mb2 ml2"
            style={{
              color: "var(--colVerCor)",
            }}
          >
            Gestor de firmas
          </p>

          <Void space={2} />

          {qrCode ? (
            showOTPInput ? (
              <>
                <span className="w100 fdr jcc bold center mb2">
                  Introduce el código de tu aplicación de autenticación
                </span>
                <Input
                  placeholder="Código OTP"
                  value={codigo}
                  onChange={(ev) => setCodigo(ev.target.value)}
                  prefix={<BarcodeOutlined className="iconoInputPrefix" />}
                />

                <Status className="mt4" text={otpError} />
                <Void space={1} />

                <Button
                  type="primary"
                  size="large"
                  className="w100"
                  onClick={verificarOTP}
                  loading={loading}
                >
                  {loading ? "Verificando..." : "Verificar OTP"}
                </Button>
              </>
            ) : (
              <>
                <span className="w100 fdr jcc bold center mb2">
                  Escanea este código QR con tu aplicación de autenticación:
                </span>
                <img src={qrCode} alt="Código QR" />
                <Button
                  type="primary"
                  size="large"
                  className="w100"
                  onClick={() => setShowOTPInput(true)}
                >
                  Listo para continuar
                </Button>
              </>
            )
          ) : secretExists ? (
            <>
              <span className="w100 fdr jcc bold center mb2">
                Introduce el código de tu aplicación de autenticación
              </span>
              <Input
                placeholder="Código OTP"
                value={codigo}
                onChange={(ev) => setCodigo(ev.target.value)}
                prefix={<BarcodeOutlined className="iconoInputPrefix" />}
              />

              <Status className="mt4" text={otpError} />
              <Void space={1} />

              <Button
                type="primary"
                size="large"
                className="w100"
                onClick={verificarOTP}
                loading={loading}
              >
                {loading ? "Verificando..." : "Verificar OTP"}
              </Button>
            </>
          ) : guardarLogin ? (
            <>
              <span className="w100 fdr jcc bold center mb2">
                Se ha enviado un código al Teléfono
              </span>
              <Input
                placeholder="Código"
                value={codigo}
                onChange={(ev) => setCodigo(ev.target.value)}
                prefix={<BarcodeOutlined className="iconoInputPrefix" />}
              />

              <Status className="mt4" text={error?.codigo} />
              <Void space={1} />

              <Button
                type="primary"
                size="large"
                className="w100"
                onClick={enviarCodigo}
                loading={loading}
              >
                {loading ? "Verificando..." : "Verificar"}
              </Button>
            </>
          ) : (
            <>
              <Input
                placeholder="Nombre de usuario"
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}
                prefix={<UserOutlined className="iconoInputPrefix" />}
              />
              <Status text={error?.username} />
              <Void />

              <Input.Password
                placeholder="Contraseña"
                type="password"
                prefix={<LockOutlined className="iconoInputPrefix" />}
                onChange={(ev) => setPass(ev.target.value)}
                onKeyPress={(ev) => pulsaTeclaPassword(ev)}
              />
              <Status text={error?.pass} />
              <Void />

              <div className="w100 fdr jcsb">
                <Checkbox
                  defaultChecked={mantenermeConectado}
                  onChange={(ev) => setMantenermeConectado(ev.target.checked)}
                >
                  Recuérdame
                </Checkbox>
              </div>

              <Void space={1} />

              <Status className="mt4" text={error?.general} />
              <Void space={1} />

              <Button
                type="primary"
                size="large"
                className="w100"
                onClick={pulsaLogin}
                loading={loading}
              >
                {loading ? "Iniciando..." : "Iniciar"}
              </Button>
            </>
          )}
        </div>
      </Card>
    </div>
  );
}
