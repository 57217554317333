import React from "react";



/**
 * @typedef {Object} Props
 * @property {string} [text=""] Texto que tendrá el componente.
 * @property {"error" | "success" | "info" | "loading"} [colorType="error"] Tipo de texto, determinará el color.
 * 
 * - error: rojo
 * - success: verde
 * - info: amarillo
 * - loading: azul
 * 
 * @property {string} [fontSize="1em"] Tamaño de fuente.
 * @property {object} [style={}] Estilo.
 * @property {object} [className=""] Class
*/

/**
 * Crea un texto de estado, por ejemplo para "Credenciales inválidas".
 * @param {Props} props
*/

export default function Status({
	text = "", // texto que aparecerá
	colorType = "error", // color del texto: "error", "success", "info", "loading"
	fontSize = "1em", // tamaño de fuente
	style = {},
	className="",
	
}) {
	
	if (!text) return null;
	
	
	// Si no viene como string, lo convierto
	let typeText = typeof text;
	if (typeText !== "string") {
		if (typeText === "object") {
			text = JSON.stringify(text);
		} else {
			text = "" + text;
		};
	};
	
	
	
	let color;
	
	switch (colorType) {
		case "error": color = "var(--colRoj)"; break;
		case "success": color = "var(--colVer)"; break;
		case "info": color = "var(--colVerOscCor)"; break;
		case "loading": color = "var(--colAzu)"; break;
		
		default: color = "var(--colNeg)";
	};
	
	
	
	// Genero estilo
	let _style = {...{
		textAlign: "center",
		color: color,
		fontSize: fontSize,
	}, ...style};
	
	
	
	return (
		
		<div
			className={"main-status " + className}
		>
			<p
				style={_style}
			>{text}</p>
		</div>
		
	);

};


