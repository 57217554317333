import axios from "axios";
import UAParser from "ua-parser-js";

export async function getIp () {
	
	try {
		let { data: res } = await axios.get("https://www.cloudflare.com/cdn-cgi/trace");
		
		
		if (!res) return null;
		
		/*
		La respuesta esperada es:
		
			fl=40f123
			h=www.cloudflare.com
			ip=46.25.71.106
			ts=1591188989.44
			visit_scheme=https
			uag=Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.142 Safari/537.36
			colo=MAD
			http=http/2
			loc=ES
			tls=TLSv1.3
			sni=plaintext
			warp=off
			
		*/
		
		
		// Declaro el objeto que contendrá la info
		let objIpInfo = {};
		
		
		// Pillo los datos que me vienen los convierto a un array de key values
		let keyValues = res.split(/\n/);
		
		
		// Recorro el array de strings (separados por \n)
		keyValues.forEach( _x => {
			let [key, value] = _x.split("="); // separo key de value
			if (key) objIpInfo[key] = value; // lo meto en objIpInfo
		});
		
		
		return objIpInfo;
		
	} catch (err) {
		return null;
	};
	
};


export async function getTrace(){

    const uaParser = new UAParser();
    const parserResult = uaParser.getResult();

    /* 	Ejemplo:
        browser = {name: "Chrome", version: "89.0.4389.82", major: "89"}
    */
    const browser = parserResult?.browser;
    
    /*
        No devuelve datos en device si es un navegador.
        device = {vendor: undefined, model: undefined, type: undefined}
    */
    let device = null;
    if ( parserResult?.device?.model ) device = parserResult?.device;

    const ipDetails = await getIp();

    const navigationData = {
        ip: ipDetails?.ip,
        browser: browser,
        device: device,
        // timestamp: Math.floor( Date.now() / 1000),
    }

    return navigationData;
}

export function formatTrace (trace) {
    
    let query = '';
    if ( !trace ) return query;
    
    const {ip, browser, device} = trace;
    if ( ip ) query += `&ip=${ip}`;
    if ( device ) query += `&device=${device.model}`;
    if ( browser ) query += `&browser=${browser.name}`;

    return query;
    
}
