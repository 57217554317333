import { Button, Checkbox, Image, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { descargar } from '../../Utils/descargar';
import { formatTrace } from '../../Utils/getIP';
import './documentosFirma.scss';

export default function DocumentoFirma ({
    firma,
    documento,
    onDescargar,
    trace,
    style = {}
}) {

    const url = `${process.env.REACT_APP_API_URL}`;


    const body = { 
        idDocumento: documento.id,
        nombre: documento.nombre,
        ruta: `${documento.directorio}/${documento.nombre}`
    };
    

    return <div className='p2 fdr aic minw25em' style={style}>
        <Image
            width={'6em'}
            src="../img/factura.png" 
            preview={false}
        /> 
        
        <div className='ml2 fdc w100 aic'>
            <span className='tal w90 bold fs2'> { documento.tipo.nombre.toUpperCase() } </span>
            
            <Button 
                className='w90'
                type="primary"
                style={{ backgroundColor: 'var(--colVerCor)', borderRadius:8, justifySelf:'center' }}
                onClick={ async () => {

                    const headers = { 
                        headers: { 
                            Authorization: `Bearer ${firma}`
                        }, 
                        responseType: 'arraybuffer' // sin esto se descarga el archivo en blanco
                    };

                    const {data: archivo} = await axios.post(`${url}/descargar?${formatTrace(trace)}`, body, headers);
    
                    if ( archivo ) {
                        onDescargar(documento.id)
                        return descargar(archivo, `${documento.nombre}`, 'application/pdf');

                    } else Modal.error({
                        content:'Error al descargar el archivo!',
                        closable: true,
                        maskClosable: true,
                        okText: 'Aceptar',
                    })
                    

                }}
            >
                Descargar
            </Button>
            
        </div>
    </div>
}