
import { Spin } from "antd";
import React from "react";
import "./cargando.scss";


/**
 * @typedef {object} Props
 * @property {boolean} [cargando=false] Si está cargando o no.
 * @property {string | object} textoCargando Por defecto "Cargando..." puede tener un nodo HTML.
*/

/**
 * Pone a un hijo en estado cargando.
 * @param {Props} props
*/

export default function Cargando({
	children,
	cargando = false,
	textoCargando = <p className="fs3">Cargando...</p>,
}) {
	
	
	return (
		
		<div
			className="main-cargando" style={{margin:"10px"}}
		>
			{ cargando &&
				<div className="fdc jcc aic cajaCargando">
					<Spin
						spinning={cargando}
						indicator={<img alt="cargando" src="../img/cargandoGana01.gif"></img>}
					/>
					{textoCargando}
				</div>
			}
			
			<div className={ cargando ? "cargando" : ""} >
				{children}
			</div>
			
			
		</div>
		
	);
	
};


