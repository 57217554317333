import MbxGlobal from "./MbxGlobal";
// import MbxSocket from "./MbxSocket";
import MbxUi from "./MbxUi";



export const mbxGlobal = new MbxGlobal();
// export const mbxSocket = new MbxSocket();
export const mbxUi = new MbxUi();

