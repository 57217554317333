
import { Card as AntCard } from "antd";
import React from "react";
import Cargando from "../Cargando/Cargando";
import "./card.scss";




/**
 * @typedef {Object} Props
 * @property {string} [width="100%"] Ancho.
 * @property {string} [mainClassName="main-card p3] Clase que tendrá el contenedor main.
 * @property {string} [childrenClassName="jcc aic"] Clase que tendrá el contenedor del hijo.
 * @property {boolean} [liftOnHover=false] Efecto de elevarse y dejar sombra con hover.
 * @property {boolean} [hideBorder=false] Elimina el borde.
 * @property {boolean} [hideBorderMobile=false] Elimina el borde automáticamente en modo móvil.
 * @property {boolean} [removePaddingMobile=false] Elimina el padding del contenedor del hijo.
 * @property {function} [onClick] onClick
 * @property {boolean} [loading=false] Determina si está cargando o no.
*/

/**
 * Crea una tarjeta.
 * @param {Props} props
*/

export default function Card({
	children,
	width = "100%",
	mainClassName="",
	childrenClassName = "",
	liftOnHover = false,
	hideBorder = false,
	// hideBorderMobile = false,
	// removePaddingMobile = false,
	padding = "24px",
	margin = "24px",
	onClick,
	loading = false,
}) {

	// let hidePadding = true;

	// if(hideBorderMobile === true && deviceType() === "mobile") hideBorder = true;
	// if(removePaddingMobile === true && deviceType() === "mobile") hidePadding = true;

	let classNames = hideBorder ? "card" : "card shadow";
	
	if (liftOnHover) classNames += " cardHover";
	
	
	
	return (

		<Cargando
			cargando={loading}
		>
			<div
				className={mainClassName}
				style={{
					width: width,
					margin:margin
				}}
				onClick={ onClick }
			>
				<div className={classNames} >
					<AntCard
						bordered={!hideBorder}
						bodyStyle={{
							padding: padding ? padding : null,
							margin: margin ? margin : null,
							width:width
						}}
					>
						<div className={childrenClassName}>
							{children}
						</div>
					</AntCard>
				</div>
			</div>
	</Cargando>
	);

};


