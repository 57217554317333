import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./config";
import { ConfigProvider } from 'antd';
import esEs from "antd/es/locale/es_ES";

// TODO: cuando descomento este codigo (recomendado para version 18 de react (la actual del proyecto)), se ponen varios botones con el simbolo de cargando hay que investigar el error
// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );


ReactDOM.render(
	// <React.StrictMode>
	<ConfigProvider
		locale={esEs}
	>
		<App />
	</ConfigProvider>
	// </React.StrictMode>
	,
	document.getElementById('root')
);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
