
import { Col, Row } from 'antd';
import React from 'react';
import Paso from '../../../components/Paso';

export default function RowPrimerPasoCabecera() {
    return <Row 
        className='pl5 pr5 pt5'
    >
        <Col
            className='jcfe pt3 pb3 pr5'
            xs={16}
            sm={14}
            md={13}
            xl={7}
            xxl={8}
        > 
            <Paso indx={1} />
        </Col>

        <Col 
            className='fdc'
            xs={24}
            xl={10}
            xxl={8}
        >
            <span className='tac bold fs5'> Descarga los siguientes documentos</span>
        </Col>

    </Row>
}