
import { Col, Row } from 'antd';
import React from 'react';

export default function FirmadoCorrectamente(){
    return <Row
        align='middle'
        justify='center'
        className='pl5 pr5'
    >
        <Col
            className='tac mt3 mb3'
            xs={16}
            lg={8}
            xl={5}
            xxl={3}
        >
            <div> <h1>Firmado Correctamente!</h1></div>

        </Col>
    </Row>
}