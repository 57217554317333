import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "antd/dist/antd.css";
import "./styles/global.scss";

import GlobalLayout from "./components/GlobalLayout/GlobalLayout";

import { lazy, Suspense } from 'react';

import Login from "./containers/Login/Login";
import Logout from "./containers/Logout/Logout";
// import Notfond from "./containers/Notfound/Notfound";
import Firma from './containers/Firma/Firma';
import Redirect from './containers/Redirect/Redirect';
import Error from './containers/Error/Error';
import SignaturesControl from "./containers/SignaturesControl/SignaturesControl";
import Praxis from "./containers/Praxis/Praxis";
import SignaturesControlIp from "./containers/SignaturesControlIp/SignaturesControlIp";
import PraxisSub from "./containers/PaxisSub/PraxisSub";

const Dashboard   = lazy( () => import ("./containers/Dashboard/Dashboard") )
const Gestion     = lazy( () => import ("./containers/Gestion/Gestion") )
const Emails      = lazy( () => import ("./containers/Emails/Emails") )
const Telefonos   = lazy( () => import ("./containers/Telefonos/Telefonos") )
const Notfound    = lazy( () => import ("./containers/Notfound/Notfound") )
const Paises      = lazy( () => import ("./containers/Paises/Paises") )  
// const GlobalLayout = lazy( () => import ("./components/GlobalLayout/GlobalLayout") )


function App() {

  /**
   * 
   * @description: devuelve si podemos acceder a la pagina o no
   */
  const puedoAcceder = () => {
    // Comprobamos si existe token
    const user = JSON.parse(localStorage.getItem("global")).user;
    if (!user || !user.token) {
      return false;
    } 

    // Pasamos a base 64 los datos del token para ver si ha caducado
    const decodedJwt = JSON.parse(window.atob(user.token.split(".")[1]));
    if (decodedJwt.exp * 1000 < Date.now()) {
      return false;
    }

    return true;
  };

  return (
    
      <BrowserRouter>
        <Routes>

          <Route 
            path='/firma/:token' 
            element={ <Firma />} 
            exact 
          />
          <Route 
            path='/redirect/:id' 
            element={ <Redirect />} 
            exact 
          />
          <Route 
            path='/error' 
            element={ <Error />} 
            exact 
          />
          
          <Route 
            path='/dashboard' 
            element={
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Dashboard/>
                  </Suspense>
                } titulo="Dashboard" replace/>
                :
                <Navigate to="/login" replace/>
            }

            // element = {lazy (() => {
            //     ( puedoAcceder() ) ? <GlobalLayout children={<Dashboard/>} titulo="Dashboard" replace/> : <Navigate to="/login" replace/>
            //   })
            // } 
            exact
          />
          <Route 
            path='/' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Dashboard/>
                  </Suspense>
                } titulo="Dashboard" replace/>
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />
          
          <Route 
            path='/gestion' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Gestion/>
                  </Suspense>
                } titulo="Gestión de firmas" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
            errorElement={() => <Notfound /> }
          />
          
          <Route 
            path='/gestion/:tipo' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Gestion/>
                  </Suspense>
                } titulo="Gestión de firmas" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />
          
          <Route 
            path='/emails' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Emails/>
                  </Suspense>
                } titulo="Emails validados" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
            errorElement={() => <Notfound /> }
          />
          <Route 
            path='/signaturesControl' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <SignaturesControl/>
                  </Suspense>
                } titulo="Signatures Control" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />
          
          <Route 
            path='/SignaturesControlIp' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <SignaturesControlIp/>
                  </Suspense>
                } titulo="Signatures Control Ip" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />
          
          <Route 
            path='/telefonos' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Telefonos/>
                  </Suspense>
                } titulo="Telefonos validados" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />

          <Route 
            path='/paises' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Paises/>
                  </Suspense>
                } titulo="Paises permitidos" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />

          <Route 
            path='/praxis' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Praxis/>
                  </Suspense>
                } titulo="Tareas Mala Praxis" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />
          <Route 
            path='/praxis/:idColaborador' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <Praxis/>
                  </Suspense>
                } titulo="Tareas Mala Praxis" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />

          <Route 
            path='/praxisSub' 
            element = {
              ( puedoAcceder() ) ? 
                <GlobalLayout children={
                  <Suspense fallback={<>-...-</>}>
                    <PraxisSub/>
                  </Suspense>
                } titulo="Tareas Mala Praxis" replace/> 
              : 
                <Navigate to="/login" replace/>
            } 
            exact
          />

          <Route 
            path='/logout' 
            element = {
              <Logout />
            } 
            exact
          />

          <Route 
            path="/login" 
            element={ 
              <Login />
            } 
            exact
          />
          
          <Route path="*" 
            element={
              <Suspense fallback={<>-...-</>}>
                <Notfound />
              </Suspense>
            } 
          />  

        </Routes>
      </BrowserRouter>
  );
}

export default App;
