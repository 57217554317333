
import { HeartFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';

export default function InformacionContacto() {
    return <Row
        align='middle'
        justify='center'
        className='pl5 pr5'
    >
        <Col
            className='tal mb3'
            xs={24}
            lg={16}
            xl={10}
            xxl={8}
        >
            <div className='taj bold'>
                Si tienes dudas sobre el proceso de firma de
                contratos, puedes ver un vídeo demostrativo
                <a target="_blank" href='https://youtu.be/rL9asq03OFw' className='colVerCor bold cp'> aquí </a>, o puedes llamarnos al <span className='colVerCor bold cp'> 900 293 264 </span> o
                escribirnos a <span className='colVerCor bold cp'> contratos@ganaenergía.com. </span>
            </div>

        </Col>
        <Col
            className='tac mt3 mb3'
            xs={24}
            lg={16}
            xl={20}
        >
            <span className='bold'>
                Made with <HeartFilled className='colRoj' /> in <span className='colVerCor bold'> Gana Energía </span>
            </span>

        </Col>
    </Row>
}